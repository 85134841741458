@media (max-width: 899px) {
    .product-review-root {
        padding: 40px 0 0 0;
    }
    .product-review-root h1 {
        font-size: 32px;
    }
    .product-review-content {
        margin: 35px 0 0 0;
    }
    .product-review-btn-scroll {
        display: none;
    }
    .product-review-list {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-padding: 5px;
        gap: 30px;
    }
    .review-card {
        scroll-snap-align: start;
        width: 250px;
    }
    .review-card-content {
        padding: 6px 12px 0 12px;
    }
}

@media (max-width: 599px) {
    .product-review-root {
        padding: 30px 0 0 0;
    }
    .product-review-root h1 {
        font-size: 28px;
    }
    .product-review-content {
        margin: 30px 0 0 0;
    }
    .product-review-btn-scroll {
        display: none;
    }
    .product-review-list {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-padding: 2px;
        gap: 25px;
    }
    .review-card {
        scroll-snap-align: center;
        width: 240px;
    }
    .review-card:active {
        background-color: rgb(230, 230, 230);
    }
    .review-card-content {
        padding: 6px 10px 0 10px;
    }
    .review-card-content h2 {
        margin-bottom: 3px;
        font-size: 16px;
    }
    .review-card-content h4{
        margin-top: 10px;
        font-size: 12px;
    }
    .review-item-bottom {
        padding: 0 10px;
        margin-bottom: 10px;
    }
    .review-item-bottom img {
        width: 30px;
        height: 30px;
    }
    .review-item-bottom-text h5,
    .review-item-bottom-text h6 {
        font-size: 9px;
    }
}