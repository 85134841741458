@media (max-width: 899px) {
    .home-second-root {
        padding: 25px 0;
    }
    .home-second-1st h2 {
        font-size: 15px;
        margin: 0 0 16px 0;
    }
    .home-second-1st p {
        font-size: 14px;
        margin: 0 0 5px 0;
    }
    .home-second-achievements {
        border-radius: 15px;
        padding: 10px 15px;
    }
    .home-second-achievements-content div img {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
    }
    .home-second-achievements-content div h3 {
        font-size: 18px;
    }
    .home-second-achievements-content div h4 {
        margin: 1px 0 0 0;
        font-size: 10px;
    }
    .home-second-2nd div {
        aspect-ratio: 2 / 1.07;
    }
}

@media (max-width: 599px) {
    .home-second-root {
        padding: 10px 0 30px 0;
    }
    .home-second-content {
        flex-direction: column;
        justify-content: unset;
    }
    .home-second-1st {
        width: var(--pageWidth);
    }
    .home-second-1st h2 {
        font-size: 18px;
        margin: 0 0 10px 0;
    }
    .home-second-1st p {
        font-size: 15px;
        margin: 0 0 20px 0;
    }
    .home-second-1st p b {
        font-family: Nunito-b;
        font-weight: normal;
    }
    .home-second-achievements {
        border-radius: 15px;
    }
    .home-second-achievements-content div img {
        width: 25px;
        height: 25px;
        margin-bottom: 3px;
    }
    .home-second-achievements-content div h3 {
        font-size: 20px;
    }
    .home-second-achievements-content div h4 {
        margin: 2px 0 0 0;
        font-size: 12px;
    }
    .home-second-2nd {
        width: var(--pageWidth);
        flex-direction: row;
        margin-top: 20px;
    }
    .home-second-2nd div {
        width: calc((var(--pageWidth) - 15px) * 0.5);
        aspect-ratio: 2 / 1.1;
        border-radius: 12px;
    }
}

@media (max-width: 399px) {
    .home-second-1st h2 {
        font-size: 16px;
    }
    .home-second-1st p {
        font-size: 13px;
    }
}