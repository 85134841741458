.review-detail-root {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes backdropIn {
    from {
      background-color: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0px);
    }
    to {
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(3px);
    }
}
  
@keyframes backdropOut {
    from {
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(3px);
    }
    to {
      background-color: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0px);
      display: none;
    }
}

.review-detail-content {
    display: none;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

@keyframes reviewContentShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes reviewContentHide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

#review-detail-title {
    width: 85%;
    color: white !important;
    position: absolute;
    top: 20px;
    left: 20px;
    margin: 0;
    font-size: 22px !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 2px 2px rgb(50, 50, 50);
}

.review-detail-close {
    width: 18px;
    height: 18px;
    padding: 4px;
    border-radius: 15px;
    border: 1px solid white;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.review-detail-close:active {
    background-color: rgba(0, 0, 0, 0.3);
}

.review-detail-preview-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.review-detail-preview-btn {
    width: fit-content;
    height: 40px;
    border-radius: 25px;
    padding: 4px;
    cursor: pointer;
}

.review-detail-preview-btn:active {
    background-color: rgba(0, 0, 0, 0.3);
}

.review-detail-preview {
    width: calc(var(--pageWidth) * 0.9);
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 18px;
    border: 2px solid rgb(200, 200, 200);
}

.review-detail-list {
    width: calc(var(--pageWidth) * 0.9);
    display: flex;
    gap: 10px;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 30px 0 0 0;
}

.review-detail-list::-webkit-scrollbar {
    display: none;
}

.review-detail-list img {
    width: 90px;
    aspect-ratio: 1 / 0.7;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
    border: var(--reviewImageItemBorder) solid transparent;
    transition: border 100ms ease-out;
}

.review-img-selected {
    border: var(--reviewImageItemBorder) solid var(--colorThird) !important;
}

:root {
    --reviewImageItemBorder: 2.5px;
}

@media (hover: hover) {
    .review-detail-close:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
    .review-detail-preview-btn:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
    .review-detail-list img:not(.review-img-selected):hover {
        border: var(--reviewImageItemBorder) solid rgba(250, 250, 250, 0.4) !important;
    }
}

@media (max-width: 899px) {
    #review-detail-title {
        font-size: 18px !important;
    }
    .review-detail-list {
        margin: 20px 0 0 0;
    }
    .review-detail-list img {
        width: 80px;
    }
    :root {
        --reviewImageItemBorder: 2px;
    }
}

@media (max-width: 599px) {
    #review-detail-title {
        font-size: 17px !important;
        top: 15px;
        left: 15px;
    }
    .review-detail-close {
        top: 15px;
        right: 15px;
    }
    .review-detail-preview-btn {
        display: none;
    }
    .review-detail-preview {
        width: var(--pageWidth);
    }
    .review-detail-list {
        width: var(--pageWidth);
        gap: 6px;
        margin: 40px 0 0 0;
    }
    .review-detail-list img {
        border-radius: 10px;
    }
}

@media (max-width: 375px) {
    #review-detail-title {
        font-size: 14px !important;
    }
}