.product-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--contentMarginBottom);
    padding-bottom: var(--contentPaddingBottom);
}

.product-root-title {
    width: var(--pageWidth);
    text-align: left;
}