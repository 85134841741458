.location-item-root {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 15px;
}

.location-item-text-header {
    width: 100%;
    font-size: 20px !important;
    margin: 15px 0 8px 5px;
    color: rgb(80, 80, 80) !important;
}

.location-item-table {
    /* Change these properties */
    width: 100%;
    --border: 1px solid var(--colorLine);
    border-radius: 8px;
    margin-bottom: 30px;

    /* Don't change these properties */
    border-spacing: 0;
    border-collapse: separate;
    border: var(--border);
    overflow: hidden;
}

.location-item-tr {
    cursor: pointer;
}

.location-item-tr td {
    border-bottom: 1px solid var(--colorLine);
    padding: 10px 0 10px 10px;
}

.location-item-tr:last-child td {
    border-bottom: 0px;
}

.location-item-tr:hover {
    background-color: rgb(240, 240, 240);
}

.location-item-container {
    display: flex;
    align-items: center;
}

.location-item-text-code {
    width: 130px;
    font-size: 14px !important;
    margin: 0;
    color: rgb(100, 100, 100) !important;
}

.location-item-text-location {
    width: 100%;
    font-size: 15px !important;
    margin: 0px;
    color: black !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}