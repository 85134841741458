.home-main-root {
    width: 100%;
    background-repeat: repeat-x;
    background-size: 60%;
    background-position: bottom;
    margin-top: 25px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
}

.home-main-content {
    width: var(--pageWidth);
    display: flex;
    justify-content: space-between;
    column-gap: 4%;
}

.home-main-first {
    width: 48%;
    display: flex;
    flex-direction: column;
}

.home-main-first h1 {
    margin: 0;
}

.home-main-first h2 {
    margin: 10px 0 0 0;
    font-size: 18px;
}

.home-main-first h3 {
    margin: 30px 0 0 0;
    font-size: 15px;
}

.home-main-first-btn-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;
    padding-right: 60px;
}

.home-main-second {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-main-second-tv {
    height: 100%;
    background-color: var(--colorContainer);
    border: 1.5px solid rgb(240, 240, 240);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.home-main-second-tv img {
    width: 75%;
    transition: transform 200ms;
}

.home-main-second-tagline {
    height: 100px;
    background-color: var(--colorContainer);
    border: 1.5px solid rgb(240, 240, 240);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
}

.home-main-second-tagline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 5px;
}

.home-main-second-tagline-item img {
    height: 30px;
}

.home-main-second-tagline-item h3 {
    margin: 0;
    font-size: 16px;
}

.home-main-second-btn {
    display: none;
    justify-content: space-between;
    margin-top: 20px;
}

.home-main-second-btn button {
    width: calc((var(--pageWidth) - 20px) * 0.5);
}

@media (hover: hover) {
    .home-main-second-tv img:hover {
        transform: scale(1.2);
    }
}