#placeholder-parent {
    padding: 0 15px;
    height: calc(100% - var(--locationHeaderHeight));
}

.shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, var(--colorShimmerBase), var(--colorShimmer), var(--colorShimmerBase));
    background-size: 1200px 100%;
    border-radius: 6px;
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }

    100% {
        background-position: 1200px 0;
    }
}

.search {
    height: 40px;
    margin-top: 15px;
}

.title {
    height: 22px;
    width: 180px;
    margin: 30px 0 10px 0;
}

.item {
    height: 30px;
    margin-top: 8px;
}

@media (max-width: 500px) /* Mobile */
and (orientation: portrait),
((max-height: 500px) and (orientation: landscape)) {
    .search {
        height: 35px;
        margin-top: 10px;
    }
}

@media (max-width: 375px) /* Mobile Small */
and (orientation: portrait),
((max-height: 375px) and (orientation: landscape)) {
    #placeholder-parent {
        padding-right: 10px;
    }
    .search {
        height: 30px;
        margin-top: 8px;
    }
    .title {
        height: 20px;
        width: 140px;
        margin: 20px 0 10px 0;
    }
    .item {
        height: 25px;
        margin-top: 5px;
    }
}