.customer-text {
	text-align: center;
    font-size: 20px !important;
    margin: 30px 0;
}

.carousel {
	overflow: hidden;
	padding: 20px 0;
	white-space: nowrap;
	position: relative;
	display: flex;
}

.carousel:before,
.carousel:after {
	position: absolute;
	top: 0;
	width: 120px;
	height: 100%;
	content: "";
	z-index: 2;
}

.carousel:before {
	left: 0;
	background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.carousel:after {
	right: 0;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.carousel:hover .carousel-content {
	animation-play-state: paused;
}

.carousel-content {
	display: flex;
}

.carousel-content img {
	height: 80px;
	margin: 0 25px;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

@media (max-width: 899px) {
	.customer-text {
		font-size: 18px !important;
		margin: 25px 0;
	}
	.carousel-root:before,
	.carousel-root:after {
		width: 100px;
	}
	.carousel-content img {
		height: 65px;
		margin: 0 20px;
	}
}

@media (max-width: 599px) {
	.customer-text {
		font-size: 16px !important;
		margin: 20px 0;
	}
	.carousel-root:before,
	.carousel-root:after {
		width: 80px;
	}
	.carousel-content img {
		height: 50px;
		margin: 0 20px;
	}
}