.home-last-root {
    width: var(--pageWidth);
    padding: 60px 0;
}

.home-last-content {
    display: flex;
}

.home-last-info {
    width: 32.5%;
}

.home-last-info h2 {
    font-size: 20px;
    margin: 0;
}

.home-last-info p {
    font-size: 14px;
    line-height: 1.6;
}

.home-last-info img {
    width: 95%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 15px;
}

.home-last-prayer {
    width: 32.5%;
    background-color: var(--colorPrimary);
    border-radius: 20px;
    background-repeat: repeat-x;
    background-size: 180%;
    background-position: bottom;
}

.home-last-mosque {
    width: 32.5%;
    border-radius: 20px;
    overflow: hidden;
}

.home-last-mosque img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 200ms;
}

@media (max-width: 899px) {
    .home-last-root {
        padding: 40px 0;
    }
    .home-last-info h2 {
        font-size: 15px;
    }
    .home-last-info p {
        font-size: 10.5px;
        line-height: 1.5;
        margin: 7px 0;
    }
    .home-last-mosque,
    .home-last-prayer {
        border-radius: 14px;
    }
    .home-last-info img {
        width: 90%;
        margin-top: 2px;
    }
}

@media (max-width: 599px) {
    .home-last-root {
        padding: 25px 0;
    }
    .home-last-content {
        flex-direction: column;
        gap: 25px;
    }
    .home-last-info {
        width: var(--pageWidth);
    }
    .home-last-info h2 {
        font-size: 20px;
    }
    .home-last-info p {
        font-size: 16px;
    }
    .home-last-info img {
        width: 85%;
        margin-top: 10px;
    }
    .home-last-prayer {
        width: var(--pageWidth);
        aspect-ratio: 1 / 1.5;
        border-radius: 16px;
    }
    .home-last-mosque {
        width: var(--pageWidth);
        aspect-ratio: 1 / 1.5;
        border-radius: 16px;
    }
}

@media (max-width: 399px) {
    .home-last-info h2 {
        font-size: 18px;
    }
    .home-last-info p {
        font-size: 14px;
    }
}

@media (hover: hover) {
    .home-last-mosque img:hover {
        transform: scale(1.2);
    }
}