@media (max-width: 899px) {
    .home-main-root {
        padding-bottom: 40px;
    }
    .home-main-first h2 {
        margin: 8px 0 0 0;
        font-size: 15px;
    }
    .home-main-first h3 {
        margin: 20px 0 0 0;
        font-size: 13px;
    }
    .home-main-first-btn-container {
        padding-right: 10px;
    }
    .home-main-second-tagline-item img {
        height: 20px;
    }
    .home-main-second-tagline-item h3 {
        font-size: 13px;
    }
}

@media (max-width: 599px) {
    .home-main-root {
        padding-bottom: 30px;
    }
    .home-main-content {
        flex-direction: column;
    }
    .home-main-first {
        width: var(--pageWidth);
    }
    .home-main-first h2 {
        margin: 10px 0 0 0;
        font-size: 17px;
    }
    .home-main-first h3 {
        margin: 10px 0 30px 0;
        font-size: 15px;
    }
    .home-main-first-btn-container {
        display: none;
    }
    .home-main-second {
        width: var(--pageWidth);
        justify-content: unset;
    }
    .home-main-second-tagline-item img {
        height: 22px;
    }
    .home-main-second-tagline-item h3 {
        font-size: 14px;
    }
    .home-main-second-btn {
        display: flex;
    }
}

@media (max-width: 390px) {
    .home-main-first h3 {
        margin: 10px 0 30px 0;
        font-size: 13px;
    }
}

@media (max-width: 350px) {
    .home-main-first h3 {
        margin: 10px 0 30px 0;
        font-size: 12px;
    }
}