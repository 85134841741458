.product-review-root {
    width: 100%;
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-review-root h1 {
    width: 100%;
    text-align: center;
    font-size: 40px;
    margin: 0;
}

.product-review-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
    gap: 10px;
}

.product-review-btn-scroll {
    width: 25px;
    height: 25px;
    background-color: var(--colorContainer);
    border-radius: 10px;  
    border: 1.5px solid rgb(240, 240, 240);
    padding: 3px;
    cursor: pointer;
}

.product-review-btn-scroll:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.product-review-list {
    width: var(--pageWidth);
    padding: 20px var(--featurePaddingSide);
    display: grid;
    grid-template-columns: min-content;
    gap: var(--reviewGap);
    grid-auto-flow: column;
    overflow-x: hidden;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.product-review-list::-webkit-scrollbar {
    display: none;
}

.review-card {
    width: calc((var(--pageWidth) - var(--reviewSpace)) / 3);
    aspect-ratio: 1 / 1.8;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 1.5px 6px 0 rgb(230, 230, 230);
    overflow: hidden;
    position: relative;
}

.review-card-content {
    padding: 10px 15px 0 15px;
}

.review-card-content h2,
.review-card-content h4 {
    margin: 0;
}

.review-card-content h2 {
    margin-bottom: 5px;
    font-size: 18px;
}

.review-card-content h4{
    margin-top: 14px;
    font-size: 13px;
}

.review-img-mosque-container {
    width: 100%;
    position: relative;
    aspect-ratio: 1 / 0.65;
    overflow: hidden;
}

.review-img-mosque {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 200ms;
}

.review-img-foreground {
    width: 100%;
    aspect-ratio: 1 / 0.65;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 200ms linear;
}

.review-item-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 15px;
    margin-bottom: 15px;
}

.review-item-bottom img {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    object-fit: cover;
}

.review-item-bottom-text {
    display: flex;
    flex-direction: column;
}

.review-item-bottom-text h5,
.review-item-bottom-text h6 {
    margin: 0 0 0 5px;
    font-size: 11px;
}

@keyframes showBtn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes hideBtn {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

:root {
    --reviewGap: 30px;
    --gapReview2: calc(var(--reviewGap) * 2);
    --paddingReviewSide2: calc(var(--featurePaddingSide) * 2);
    --reviewSpace: calc(var(--gapReview2) + var(--paddingReviewSide2));
}

@media (hover: hover) {
    .review-img-mosque-container:hover {
        cursor: zoom-in;
        .review-img-foreground {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
    .review-card:hover {
        background-color: rgb(245, 245, 245);
        cursor: pointer;
        .review-img-mosque {
            transform: scale(1.2);
        }
    }
}

@media (max-width: 599px) {
    :root {
        --reviewGap: 50px;
    }
}