@media (max-width: 899px) {
    .product-feature-root {
        padding: 40px 0 20px 0;
    }
    .product-feature-root h1 {
        font-size: 28px;
    }
    .product-feature-content {
        margin: 25px 0 0 0;
        gap: 0;
    }
    .product-feature-btn-scroll {
        display: none;
    }
    .product-feature-list {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scroll-padding: 5px;
    }
    .product-feature-card {
        width: 230px;
        scroll-snap-align: start;
        border-radius: 20px;
        box-shadow: 1.2px 1px 6px 0 rgb(240, 240, 240);
    }
    .product-feature-card h2 {
        font-size: 12px;
        width: 26px;
        line-height: 26px;
    }
    .product-feature-card-content img {
        width: 42px;
        height: 42px;
    }
    .product-feature-card-content h3 {
        font-size: 15px;
    }
}

@media (max-width: 599px) {
    .product-feature-root {
        padding: 30px 0;
    }
    .product-feature-root h1 {
        font-size: 20px;
    }
    .product-feature-content {
        margin: 20px 0 0 0;
        gap: 0;
    }
    .product-feature-btn-scroll {
        display: none;
    }
    .product-feature-list {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scroll-padding: 2px;
    }
    .product-feature-card {
        width: 200px;
        scroll-snap-align: center;
        border-radius: 14px;
        padding: 5px;
        box-shadow: 1px 1px 6px 0 rgb(240, 240, 240);
    }
    .product-feature-card h2 {
        font-size: 11px;
        width: 22px;
        line-height: 22px;
    }
    .product-feature-card-content img {
        width: 38px;
        height: 38px;
    }
    .product-feature-card-content h3 {
        font-size: 14px;
    }
}

