@media (max-width: 899px)
and (min-width: 600px) {
    .prayer-left {
        width: 47%;
        margin: 7px 0 0 7px;
    }
    .prayer-left h3 {
        font-size: 9px;
    }
    .prayer-left-prayer,
    .prayer-left-prayer-time {
        font-size: 16px !important;
        margin: 0 !important;
    }
    .prayer-left-prayer {
        margin: 3px 0 0 0 !important;
    }
    .prayer-left-prayer-time {
        margin: -3px 0 3px 0 !important;
    }
    .prayer-left-date {
        line-height: 1.7;
        font-size: 8px !important;
    }
    .prayer-right {
        width: 47%;
        margin: 7px 7px 0 0;
    }
    .btn-zone {
        padding: 3px 8px;
        border-radius: 12px;
        font-size: 10px;
        margin: -3px -6px 0 0;
    }
    .prayer-menu {
        width: 13px;
        height: 9px;
        margin-left: 6px;
    }
    .prayer-menu span {
        height: 1.7px;
    }
    .prayer-code {
        font-size: 12px !important;
        margin: 2px 0 !important;
    }
    .prayer-location {
        font-size: 9px !important;
        -webkit-line-clamp: 3;
    }
    .prayer-counter {
        font-size: 7px !important;
        margin-top: 4px !important;
    }
    .prayer-item {
        padding: 5px 5px;
        margin: 4px 3px;
    }
    .prayer-item h3 {
        font-size: 11px !important;
    }
    .home-last-prayer h4 {
        font-size: 6px;
        margin: 0 0 7px 0;
    }
}

@media (max-width: 599px) {
    .prayer-left h3 {
        font-size: 17px;
    }
    .prayer-left-prayer,
    .prayer-left-prayer-time {
        font-size: 26px !important;
        margin: 0 !important;
    }
    .prayer-left-prayer {
        margin: 5px 0 0 0 !important;
    }
    .prayer-left-prayer-time {
        margin: -3px 0 5px 0 !important;
    }
    .prayer-left-date {
        line-height: 1.7;
        margin-bottom: 3px !important;
    }
    .btn-zone {
        font-size: 16px;
    }
    .prayer-code {
        font-size: 20px !important;
    }
    .prayer-location {
        font-size: 15px !important;
        -webkit-line-clamp: 3;
    }
    .prayer-counter {
        font-size: 14px !important;
    }
    .prayer-item {
        padding: 10px 10px;
        margin: 5px 5px;
    }
    .prayer-item h3 {
        font-size: 19px !important;
    }
    .home-last-prayer h4 {
        font-size: 10px;
        margin: 0 0 10px 0;
    }
}

@media (max-width: 380px) {
    .prayer-left h3 {
        font-size: 14px;
    }
    .prayer-left-prayer,
    .prayer-left-prayer-time {
        font-size: 24px !important;
        margin: 0 !important;
    }
    .prayer-left-prayer {
        margin: 0 !important;
    }
    .prayer-left-prayer-time {
        margin: -3px 0 5px 0 !important;
    }
    .prayer-left-date {
        line-height: 1.5;
    }
    .btn-zone {
        font-size: 14px;
    }
    .prayer-code {
        font-size: 18px !important;
    }
    .prayer-location {
        font-size: 14px !important;
    }
    .prayer-counter {
        font-size: 11px !important;
    }
    .prayer-item h3 {
        font-size: 15px !important;
    }
    .home-last-prayer h4 {
        font-size: 9px;
        margin: 0 0 10px 0;
    }
}