:root {
    --star-size: 18px;
    --star-color: rgb(230, 230, 230);
    --star-background: var(--colorThird);
}

.stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: var(--star-size);
    line-height: 1;
}

.stars::before {
    content: '★★★★★';
    letter-spacing: 0px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 599px) {
    :root {
        --star-size: 16px;
    }
}