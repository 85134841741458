@media (max-width: 899px) {
    .product-main-root h3 {
        font-size: 16px;
        margin: 0 0 18px 0;
    }
    .product-main-content {
        height: 270px;
    }
    .product-main-video-info h2 {
        font-size: 16px;
        margin: 15px 0 0 15px;
    }
    .product-main-video-info img:active {
        width: 80px;
        height: 80px;
        transition: 400ms;
    }
    .product-main-achievements {
        padding: 12px 0;
    }
    .product-main-achievements-item-top img {
        width: 26px;
        height: 26px;
        margin-right: 5px;
    }
    .product-main-achievements-item-top h1 {
        font-size: 26px;
    }
    .product-main-achievements-item h4 {
        margin: 1px 0 0 0;
        font-size: 10px;
    }
}

@media (max-width: 599px) {
    .product-main-root h3 {
        font-size: 16px;
        margin: 0 0 15px 0;
    }
    .product-main-content {
        flex-direction: column;
        height: unset;
        justify-content: unset;
    }
    .product-main-video {
        width: 100%;
        aspect-ratio: 2 / 1.2;
        border-radius: 14px;
    }
    .product-main-video-info h2 {
        font-size: 14px;
        margin: 10px 0 0 15px;
    }
    .product-main-video-info h6 {
        font-size: 12px;
        border-radius: 4px;
        margin: 0 12px 12px 0;
    }
    .product-main-video-info img {
        width: 60px;
        height: 60px;
    }
    .product-main-video-info img:active {
        width: 70px;
        height: 70px;
        transition: 400ms;
    }
    .product-main-achievements {
        width: 100%;
        border-radius: 14px;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        margin: 20px 0;
    }
    .product-main-achievements-content {
        width: 100%;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        padding: 0 20px;
    }
    .product-main-achievements-item {
        align-items: center;
    }
    .product-main-achievements-item-top {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .product-main-achievements-item-top img {
        width: 24px;
        height: 24px;
        margin: 0 0 2px 0;
    }
    .product-main-achievements-item-top h1 {
        font-size: 20px;
    }
    .product-main-achievements-item h4 {
        margin: 2px 0 0 0;
        font-size: 10px;
        text-align: center;
    }
}