.home-root {
    width: 100%;
    margin-bottom: var(--contentMarginBottom);
    padding-bottom: var(--contentPaddingBottom);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-feature-info {
    width: var(--pageWidth);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--colorPrimary);
    border-radius: 22px;
    margin: 50px 0 80px 0;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.home-feature-info-content {
    max-width: 60%;
}

.home-feature-info-content h2 {
    font-size: 20px;
    color: white;
    margin: 0;
}

.home-feature-info-content p {
    color: white;
    margin: 10px 0 0 0;
    font-size: 16px;
}

.home-feature-info button {
    margin: 0;
}

.home-customer-container {
    width: var(--pageWidth);
}

@media (max-width: 899px) {
    .home-feature-info {
        border-radius: 18px;
        margin: 30px 0 40px 0;
        padding: 15px;
    }
    .home-feature-info-content {
        max-width: 70%;
    }
    .home-feature-info-content h2 {
        font-size: 15px;
    }
    .home-feature-info-content p {
        font-size: 13px;
    }
    .home-feature-info button {
        margin: 0;
    }
}

@media (max-width: 599px) {
    .home-feature-info {
        flex-direction: column;
        margin: 0 0 40px 0;
    }
    .home-feature-info-content {
        max-width: 100%;
        text-align: center;
    }
    .home-feature-info-content h2 {
        font-size: 18px;
    }
    .home-feature-info-content p {
        font-size: 16px;
    }
    .home-feature-info button {
        margin: 20px 0 0 0;
    }
}

@media (max-width: 399px) {
    .home-feature-info-content h2 {
        font-size: 16px;
    }
    .home-feature-info-content p {
        font-size: 14px;
    }
}