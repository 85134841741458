.package-price-root {
    width: 100%;
    padding: 45px 0;
    background-color: var(--colorContainer);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.package-price-content {
    width: var(--pageWidth);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.package-price-header {
    width: 100%;
}

.package-price-header h3 {
    margin: 0;
    font-size: 24px;
}

.package-price-header p {
    text-align: center;
    margin: 15px 0 0 0;
    font-size: 16px;
}

.package-price-list {
    width: 90%;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.package-price-list span {
    width: calc(var(--pageWidth) * 0.415);
}

.package-card {
    width: calc(var(--pageWidth) * 0.415);
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgb(220, 220, 220);
    padding: 25px;
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}

.package-card h2 {
    margin: 0;
    font-size: 18px;
    font-family: Nunito-b;
    font-weight: normal;
    color: var(--colorPrimary);
}

.package-card p {
    margin: 20px 0;
    font-size: 15px;
    line-height: 1.6;
    text-align: justify;
}

.package-card ol,
.package-card ul {
    line-height: 1.8;
    font-family: Nunito-sb;
    font-weight: normal;
    color: var(--colorPrimary);
    font-size: 16px;
}

.package-card ol {
    padding-left: 1rem;
}

.package-card ul {
    list-style: none;
    padding-left: 0;
}

.package-card ul li {
    background: url('../../../../../public/icons/ic-check.svg') no-repeat left top;
    background-size: 18px 18px;
    background-position: 8px 8px;
    background-repeat: no-repeat;
    padding: 2.5px 0 0 32px;
}

.package-card-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}