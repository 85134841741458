@media (max-width: 899px) {
    .contact-content {
        background-size: 110%;
    }
    .contact-content-container {
        border-radius: 20px;
        padding: 20px;
        margin-top: 35px;
    }
    .contact-content-info {
        width: 47%;
    }    
    .contact-content-info h2 {
        font-size: 23px;
        margin: 0 0 10px 0;
    }
    .contact-info-item {
        margin-top: 8px;
    }
    .contact-info-item img {
        width: 16px;
        height: 14px;
        margin-right: 8px;
        margin-top: 2px;
    }
    .contact-info-item h3 {
        font-size: 15px;
    }
    .contact-content-map {
        width: 50%;
        height: auto;
        background-color: white;
        border-radius: 15px;
        text-align: center;
    }
}

@media (max-width: 599px) {
    .contact-content {
        background-size: 250%;
    }
    .contact-content-container {
        flex-direction: column;
        border-radius: 15px;
        padding: 15px;
        margin-top: 30px;
    }
    .contact-content-info {
        width: 100%;
    }
    .contact-content-info h2 {
        font-size: 22px;
    }
    .contact-info-item {
        margin-top: 15px;
    }
    .contact-info-item img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        margin-top: 2px;
    }
    .contact-info-item h3 {
        font-size: 18px;
    }
    .contact-content-map {
        width: 100%;
        aspect-ratio: 2 / 1.2;
        border-radius: 12px;
        text-align: center;
        margin-top: 50px;
    }
}