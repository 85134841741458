.prayer-root {
    height: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.prayer-left {
    width: 44%;
    margin: 15px 0 0 15px;
}

.prayer-left h3 {
    margin: 0;
    font-family: Nunito;
    font-weight: normal;
    color: white;
    font-size: 12px;
}

.prayer-left-prayer,
.prayer-left-prayer-time {
    font-family: Nunito-b !important;
    font-size: 22px !important;
    margin: 0 !important;
    text-decoration: none;
}

.prayer-left-prayer {
    margin: 5px 0 0 0 !important;
}

.prayer-left-prayer-time {
    margin: -6px 0 5px 0 !important;
}

.prayer-left-date {
    line-height: 1.7;
    font-family: Nunito-sb !important;
    margin-bottom: 5px !important;
}

.prayer-right {
    width: 44%;
    text-align: end;
    margin: 15px 15px 0 0;
}

.prayer-right h3 {
    margin: 0;
    font-family: Nunito;
    font-weight: normal;
    color: white;
    font-size: 12px;
}

.btn-zone {
    justify-content: end;
    align-items: center;
    cursor: pointer;
    padding: 4px 11px;
    background-color: unset;
    border: unset;
    border-radius: 15px;
    font-family: Nunito-b;
    font-weight: normal;
    font-size: 14px;
    color: white;
    margin: -4px -9px 0 0;
}

.btn-zone:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.prayer-menu {
    width: 16px;
    height: 11px;
    margin-left: 8px;
}

.prayer-menu span {
    height: 2px;
    border-radius: 5px;
    background-color: white;
}

.prayer-code {
    font-family: Nunito-sb !important;
    font-size: 18px !important;
    margin: 3px 0 !important;
}

.prayer-location {
    font-size: 12px !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.prayer-counter {
    font-family: Nunito-b !important;
    font-size: 11px !important;
    margin-top: 6px !important;
}

.prayer-item {
    padding: 8px 10px;
    border-radius: 25px;
    margin: 8px 5px;
}

.prayer-item-even {
    background-color: rgb(17, 93, 46);
}

.prayer-item-selected {
    background-color: var(--colorPrimaryLight);
}

.prayer-item h3 {
    color: white !important;
    font-size: 15px !important;
    margin: 0;
}

.home-last-prayer h4 {
    width: 100%;
    text-align: center;
    font-family: Nunito-sbi;
    font-weight: normal;
    font-size: 9px;
    color: white;
    margin: 5px 0 15px 0;
}

@media (hover: hover) {
    .btn-zone:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}