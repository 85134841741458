.btn,
.btnSmall {
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1.5px solid var(--colorSecondary);
    cursor: pointer;
    font-family: Nunito-b;
    font-weight: normal;
    font-size: 15px;
    color: var(--colorPrimary);
    background-color: transparent;
}

.btn-match {
    width: 100%;
}

.btn:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.btn-primary {
    background-color: var(--colorSecondary);
    border: unset;
    color: white;
}

.btn-primary:active {
    background-color: var(--colorSecondaryHover);
}

.btn img {
    height: 30px;
}

.btnSmall {
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 25px;
}

.btnSmall:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.btnSmall img {
    height: 24px;
}

/* Button Type 2 */
.btn2 {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 20px;
    font-family: Nunito-b;
    font-weight: normal;
    border-radius: 16px;
    color: var(--colorPrimary);
    cursor: pointer;
    border: none;
    text-decoration: none;
    background-color: var(--colorThird);
    font-size: 16px;
}

.btn2:active {
    background-color: var(--colorThirdHover);
}

.btn2-primary {
    background-color: var(--colorSecondary);
    color: white;
    min-width: 0;
}

.btn2-primary:active {
    background-color: var(--colorSecondaryHover);
}

.btn2 img {
    height: 22px;
    margin: 0 10px 0 0;
}

@media (hover: hover) {
    .btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .btn-primary:hover {
        background-color: var(--colorSecondaryHover);
    }
    .btnSmall:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .btn2:hover {
        background-color: var(--colorThirdHover);
    }
    .btn2-primary:hover {
        background-color: var(--colorSecondaryHover);
    }
}

@media (max-width: 899px) {
    .btn {
        padding: 13px;
        font-size: 14px;
    }
    .btnSmall {
        padding: 7px 7px 7px 15px;
        font-size: 12px;
        border-radius: 25px;
    }
    .btn img {
        height: 25px;
    }
    .btn2 {
        padding: 17px;
        border-radius: 16px;
        font-size: 13px;
    }
    .btn2 img {
        height: 20px;
        margin: 0 8px 0 0;
    }
}