.home-second-root {
    width: 100%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
}

.home-second-content {
    width: var(--pageWidth);
    display: flex;
    justify-content: space-between;
}

.home-second-1st {
    width: 46%;
    display: flex;
    flex-direction: column;
}

.home-second-1st h2 {
    font-size: 18px;
    margin: 0 0 20px 0;
}

.home-second-1st p {
    text-align: justify;
    font-size: 16px;
    margin: 0 0 40px 0;
}

.home-second-1st p b {
    font-family: Nunito-b;
    font-weight: normal;
}

.home-second-achievements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--colorContainer);
    border: 1.5px solid rgb(240, 240, 240);
    border-radius: 20px;
    padding: 15px 25px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.home-second-achievements-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.home-second-achievements-content div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-second-achievements-content div img {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
}

.home-second-achievements-content div h3 {
    margin: 0;
    color: var(--colorSecondary);
    font-size: 25px;
}

.home-second-achievements-content div h4 {
    margin: 3px 0 0 0;
    text-align: center;
    font-size: 14px;
}

.home-second-2nd {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-second-2nd div {
    width: 100%;
    aspect-ratio: 2 / 0.95;
    border-radius: 15px;
    overflow: hidden;
}

.home-second-2nd div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 200ms;
}

@media (hover: hover) {
    .home-second-2nd div img:hover {
        transform: scale(1.2);
    }
}