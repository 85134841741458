@media (max-width: 899px) {
    .package-price-root {
        padding: 40px 0;
    }
    .package-price-header h3 {
        font-size: 24px;
    }
    .package-price-header p {
        margin: 12px 0 0 0;
        font-size: 14px;
    }
    .package-price-list {
        width: 100%;
    }
    .package-price-list span {
        width: calc(var(--pageWidth) * 0.47);
    }
    .package-card {
        width: calc(var(--pageWidth) * 0.47);
        border-radius: 18px;
        padding: 15px;
    }
    .package-card h2 {
        font-size: 13px;
    }
    .package-card p {
        margin: 15px 0;
        font-size: 13px;
        line-height: 1.5;
    }
    .package-card ol,
    .package-card ul {
        line-height: 1.6;
        font-size: 13px;
    }
    .package-card ul li {
        background-size: 15px 15px;
        background-position: 5px 5px;
        padding: 2.5px 0 0 26px;
    }
    .package-card-btn-container {
        margin-top: 20px;
    }
}

@media (max-width: 599px) {
    .package-price-root {
        padding: 25px 0 30px 0;
    }
    .package-price-header h3 {
        font-size: 26px;
    }
    .package-price-header p {
        font-size: 16px;
        text-align: justify;
    }
    .package-price-list {
        width: var(--pageWidth);
        flex-direction: column;
        gap: 40px;
    }
    .package-price-list span {
        width: 100%;
    }
    .package-card {
        width: 100%;
    }
    .package-card h2 {
        font-size: 18px;
    }
    .package-card p {
        font-size: 16px;
    }
    .package-card ol,
    .package-card ul {
        font-size: 16px;
    }
}

@media (max-width: 399px) {
    .package-price-header h3 {
        font-size: 22px;
    }
    .package-price-header p {
        font-size: 14px;
    }
    .package-card h2 {
        font-size: 16px;
    }
    .package-card p {
        font-size: 14px;
    }
    .package-card ol,
    .package-card ul {
        font-size: 14px;
    }
}