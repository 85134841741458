.faq-item {
    background-color: white;
    border-radius: 20px;
    margin-top: 30px;
    box-shadow: 0 2px 6px 0 rgb(240, 240, 240);
    padding: 0;
    overflow: hidden;
}

.faq-item-question {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 30px;
    min-height: 70px;
    cursor: pointer;
    overflow: hidden;
}

.faq-item-question:active {
    background-color: var(--colorContainer);
}

.faq-selected {
    background-color: var(--colorContainer);
}

.faq-item-question h3 {
    font-size: 18px;
}

.faq-item-question img {
    width: 34px;
    height: 34px;
    transition: rotate 250ms ease-out;
    transition-delay: 50ms;
}

.faq-item-question-arrow {
    rotate: 90deg;
}

.faq-answer-container {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 250ms ease-out;
}

.faq-answer-container-open {
    grid-template-rows: 1fr;
}

.faq-answer {
    padding: 0 30px;
    overflow: hidden;
}

.faq-answer p {
    text-align: justify;
    font-family: Nunito;
    font-weight: normal;
    color: var(--colorPrimaryLight);
    line-height: 1.6;
    font-size: 16px;
}

.faq-answer ol,
.faq-answer ul {
    padding-left: 2rem;
    line-height: 1.8;
    font-size: 16px;
}

.faq-answer ol li {
    font-family: Nunito-sb;
    font-weight: normal;
    color: var(--colorPrimaryLight);
    padding-left: 0;
}

.faq-answer ul li {
    text-align: justify;
    font-family: Nunito-sb;
    font-weight: normal;
    color: var(--colorPrimaryLight);
    padding-left: 0;
}

@media (max-width: 899px) {
    .faq-item-question:hover {
        background-color: unset;
    }
}

@media (max-width: 599px) {
    .faq-item {
        border-radius: 15px;
        margin-top: 20px;
    }
    .faq-item-question {
        align-items: center;
        padding: 0 8px 0 15px;
        min-height: 60px;
    }
    .faq-item-question:hover {
        background-color: unset;
    }
    .faq-item-question h3 {
        font-size: 13px;
    }
    .faq-item-question img {
        width: 28px;
        height: 28px;
    }
    .faq-answer {
        padding: 0 10px;
    }
    .faq-answer p {
        font-size: 13px;
    }
    .faq-answer ol,
    .faq-answer ul {
        padding-left: 1.1rem;
        font-size: 13px;
    }
}

@media (hover: hover) {
    .faq-item-question:hover {
        background-color: var(--colorContainer);
    }
}