.text,
.textSemiBold,
.textBold {
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    color: var(--colorPrimary);
}

.textSemiBold {
    font-family: Nunito-sb;
}

.textBold {
    font-family: Nunito-b;
}

.textTitle,
.textTitleBold,
.textHeader {
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    color: var(--colorPrimary);
}

.textTitleBold,
.textHeader {
    font-family: Barlow-b;
}

.textHeader {
    font-size: 30px;
    margin: 25px 0;
}

.textBlack {
    font-family: Barlow-bl;
    font-weight: normal;
}

@media (max-width: 899px) {
    .textHeader {
        font-size: 28px;
        margin: 23px 0;
    }
}

@media (max-width: 599px) {
    .textHeader {
        font-size: 25px;
        margin: 20px 0;
    }
}