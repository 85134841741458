.about-root {
    width: 100%;
    display: flex;
    justify-content: center;
    background-repeat: repeat-x;
    background-size: 60%;
    background-position: bottom;
}

.about-content {
    width: var(--pageWidth);
    margin-bottom: var(--contentMarginBottom);
    padding-bottom: var(--contentPaddingBottom);
}

.about-img-header {
    width: 100%;
    aspect-ratio: 2 / 0.75;
    border-radius: 20px;
    overflow: hidden;
}

.about-img-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 80%;
    transition: transform 200ms;
}

.about-p {
    font-size: 20px !important;
    text-align: justify;
    margin-top: 40px;
}

.about-p b {
    font-family: Nunito-b;
    font-weight: normal;
}

.about-content ul {
    line-height: 1.6;
    padding-left: 1.1rem;
}

.about-content ul li {
    font-size: 20px;
    margin-top: 13px;
}

.about-image-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-flow: dense;
    /* don't forget this */
    justify-content: center;
    column-gap: var(--gridGap);
    row-gap: var(--gridGap);
    margin: 70px 0;
}

.about-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 200ms;
}

.about-grid-width {
    width: calc((var(--pageWidth) - (var(--gridGap) * 2)) * 0.3333);
}

.grid-corner {
    border-radius: 20px;
    overflow: hidden;
}

.about-grid1 {
    aspect-ratio: 1 / 0.75;
}

.about-grid2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    aspect-ratio: 1 / 0.75;
    background-color: var(--colorPrimary);
    padding: 0;
}

.about-grid2 h2 {
    color: white;
    margin: 0;
    padding: 0;
    font-size: 22px;
    padding: 0 20px 30px 20px;
}

.about-grid3 {
    height: 100%;
    grid-row: span 2;
}

.about-grid4 {
    width: 100%;
    aspect-ratio: 1 / 0.4;
    grid-column: span 2;
    object-position: 0% 0%;
}

:root {
    --gridGap: 10px;
}

@media (hover: hover) {
    .about-img-header img:hover,
    .about-image-container img:hover {
        transform: scale(1.2);
    }
}