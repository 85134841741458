@media (max-width: 899px) {
    :root {
        --gridGap: 7px;
    }
    .about-content {
        background-size: 180%;
    }
    .about-img-header {
        border-radius: 17px;
    }
    .about-p {
        font-size: 18px !important;
        margin-top: 25px;
    }
    .about-content ul li {
        font-size: 18px;
        margin-top: 8px;
    }
    .about-image-container {
        margin: 50px 0;
    }
    .grid-corner {
        border-radius: 15px;
    }
    .about-grid2 h2 {
        font-size: 14px;
        padding: 0 12px 16px 12px;
    }
}

@media (max-width: 599px) {
    :root {
        --gridGap: 5px;
    }
    .about-content {
        background-size: 250%;
    }
    .about-img-header {
        border-radius: 12px;
    }
    .about-p {
        font-size: 15px !important;
        margin-top: 22px;
    }
    .about-content ul li {
        font-size: 15px;
        margin-top: 6px;
    }
    .about-image-container {
        margin: 40px 0;
    }
    .grid-corner {
        border-radius: 10px;
    }
    .about-grid2 h2 {
        font-size: 9px;
        padding: 0 8px 12px 8px;
    }
}