.product-main-root {
    width: var(--pageWidth);
    display: flex;
    flex-direction: column;
}

.product-main-root h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
}

.product-main-content {
    display: flex;
    height: 400px;
    justify-content: space-between;
}

.product-main-video {
    width: 70%;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
}

.product-main-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-main-video-info {
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.65);
    position: absolute;
    left: 0;
    top: 0;
}

.product-main-video-info h2 {
    color: white;
    font-size: 18px;
    margin: 20px 0 0 25px;
}

.product-main-video-info h6 {
    width: fit-content;
    color: white;
    font-size: 13px;
    background-color: rgb(70, 70, 70);
    padding: 2px 8px;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 15px 15px 0;
}

.product-main-video-info img {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: width 300ms, height 300ms;
}

.product-main-achievements {
    width: 28%;
    background-color: var(--colorContainer);
    border-radius: 16px;
    border: 1.5px solid rgb(240, 240, 240);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 30px 0;
}

.product-main-achievements-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.product-main-achievements-item {
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.product-main-achievements-item-top {
    width: fit-content;
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 0;
}

.product-main-achievements-item-top img {
    width: 33px;
    height: 33px;
    margin-right: 10px;
}

.product-main-achievements-item-top h1 {
    width: auto;
    margin: 0;
    color: var(--colorSecondary);
    font-size: 35px;
}

.product-main-achievements-item h4 {
    margin: 3px 0 0 0;
    font-size: 13px;
}

@media (hover: hover) {
    .product-main-video-info img:hover {
        width: 85px;
        height: 85px;
    }
}