.footer-top,
.footer-content {
    background-repeat: repeat-x;
    background-size: 78%;
    background-position: top;
}

.footer-top {
    width: 700px;
    background-color: var(--colorPrimary);
    border-radius: 30px;
    min-height: 200px;
    justify-content: center;
    z-index: 1;
}

.footer-top h2 {
    color: white;
    text-align: center;
    font-size: 28px;
    margin: 0;
}

.footer-btn {
    border-radius: 15px;
    background-color: var(--colorThird);
    padding: 5px 15px;
    cursor: pointer;
    margin-top: 30px;
}

.footer-btn img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.footer-btn h4 {
    text-decoration: none;
    margin: 0;
    font-size: 16px;
}

.footer-content {
    width: 100%;
    height: 300px;
    margin-top: -100px;
    background-color: var(--colorSecondary);
}

.footer-bottom-container {
    display: flex;
    justify-content: center;
}

.footer-bottom {
    width: var(--pageWidth);
    padding-bottom: 30px;
}

.footer-bottom-top {
    display: flex;
}

.footer-bottom-top img {
    height: 28px;
    background-color: white;
    border-radius: 16px;
    padding: 5px 8px;
    cursor: pointer;
}

.footer-bottom-top div {
    align-items: end;
}

.footer-bottom-top div h4 {
    font-size: 16px;
    margin: 0 0 0 15px;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 15px;
}

.footer-bottom-top div h4:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.footer-bottom hr {
    border: 0.8px solid white;
    border-radius: 1px;
    margin: 25px 0 10px 0;
}

.footer-copyright-img {
    width: 20px;
    height: 20px;
}

.footer-copyright-text {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    font-size: 16px !important;
    color: white !important;
}

.footer-socmed {
    width: 26px;
    height: 26px;
    margin-left: 15px;
    cursor: pointer;
}

@media (hover: hover) {
    .footer-btn:hover {
        background-color: var(--colorThirdHover);
    }
}