.product-feature-root {
    width: 100%;
    padding: 100px 0 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-feature-root h1 {
    width: 100%;
    text-align: center;
    font-size: 35px;
    margin: 0;
}

.product-feature-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
    gap: 10px;
}

.product-feature-btn-scroll {
    width: 25px;
    height: 25px;
    background-color: var(--colorContainer);
    border-radius: 10px;  
    border: 1.5px solid rgb(240, 240, 240);
    padding: 3px;
    cursor: pointer;
}

.product-feature-btn-scroll:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.product-feature-list {
    width: var(--pageWidth);
    padding: 20px var(--featurePaddingSide);
    display: grid;
    grid-template-columns: min-content;
    gap: var(--featureGap);
    grid-auto-flow: column;
    overflow-x: hidden;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.product-feature-list::-webkit-scrollbar {
    display: none;
}

.product-feature-card {
    width: calc((var(--pageWidth) - var(--featureSpace)) / 3);
    aspect-ratio: 1 / 1;
    background-color: white;
    border-radius: 24px;
    padding: 8px;
    box-shadow: 2px 1px 8px 0 rgb(240, 240, 240);
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.product-feature-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0 0 0;
}

.product-feature-card h2 {
    width: 28px;
    line-height: 28px;
    aspect-ratio: 1 / 1;
    font-size: 13px;
    margin: 0;
    position: absolute;
    text-align: center;
    background-color: var(--colorThird);
    border-radius: 50%;
}

.product-feature-card-content img {
    width: 3.2rem;
    height: 3.2rem;
}

.product-feature-card-content h3 {
    font-size: 1.1rem;
    text-align: center;
    margin: 1rem 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

@keyframes showBtn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes hideBtn {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

:root {
    --featureGap: 40px;
    --featurePaddingSide: 8px;
    --gapFeature2: calc(var(--featureGap) * 2);
    --paddingFeatureSide2: calc(var(--featurePaddingSide) * 2);
    --featureSpace: calc(var(--gapFeature2) + var(--paddingFeatureSide2));
}

@media (max-width: 899px) {
    :root {
        --featureGap: 20px;
        --featurePaddingSide: 5px;
    }
}

@media (max-width: 599px) {
    :root {
        --featureGap: 15px;
        --featurePaddingSide: 2px;
    }
}