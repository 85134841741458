.active {
    background-color: var(--colorThird);
    color: white;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: white;
    top: 0;
    padding: 0 calc((100% - var(--pageWidth)) / 2);
    min-height: 80px;
    transition: box-shadow 0.3s ease-in-out;
    z-index: 2;
}

.nav-shadow {
    box-shadow: 0 0px 10px 0px rgba(140, 140, 140, 0.5);
}

nav .title {
    width: auto;
    height: 40px;
    margin: 0;
}

nav .menu {
    display: none;
    position: absolute;
    top: 1.4rem;
    right: 15px;
    flex-direction: column;
    justify-content: space-between;
    width: 1.7rem;
    height: 1.2rem;
}

nav .menu span {
    height: 0.24rem;
    width: 100%;
    background-color: var(--colorPrimary);
    border-radius: 0.2rem;
}

nav ul {
    display: flex;
    padding: 0;
}

.showMenu {
    display: flex;
    animation: menuShow 200ms linear;
}

.hideMenu {
    display: flex;
    animation: menuHide 200ms ease-out;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    font-family: Nunito-b;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    color: var(--colorPrimary);
    padding: 5px 15px;
    border-radius: 10px;
    margin-left: 5px;
}

nav ul li a:not(.active):active {
    background-color: var(--colorContainer);
}

nav ul .socmed {
    display: none;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

nav ul .socmed img {
    width: 28px;
    height: 28px;
    margin: 0 12px;
}

nav ul .copyright-container {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

nav ul .copyright-container img {
    width: 14px;
    height: 14px;
}

nav ul .copyright-container h3 {
    font-size: 14px;
    margin-left: 5px;
}

.menu-mobile-show {
    left: 0;
}

.menu-mobile-hide {
    left: 100%;
}

@media (max-width: 899px) {
    nav {
        min-height: 65px;
    }
    nav .title {
        height: 28px;
    }
    nav ul li a {
        font-size: 13px;
        padding: 3px 10px;
        border-radius: 7px;
    }
}

@media (max-width: 599px) /* Mobile */ {
    .active {
        box-shadow: none;
    }
    nav {
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
    }

    nav .title {
        height: 36px;
        margin-top: 1rem;
        text-decoration: none;
    }

    nav .menu {
        display: flex;
    }

    nav ul {
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: auto;
        margin-top: 20px;
        position: relative;
        left: 100vw;
        display: none;
        transition: left 300ms ease-in;
    }

    .showMenu {
        left: 0;
    }

    .hideMenu {
        display: none;
        left: 100vw;
    }

    nav ul li a {
        margin-left: 0;
        background-color: rgb(254, 254, 254);
        font-size: 18px;
        margin: 10px 0;
        padding: 10px 20px;
        border-radius: 10px;
        box-shadow: inset rgb(240, 240, 240) 1px 2px 4px 0px , inset rgb(252, 252, 252) -3.2px -2.2px 2px 0px;
    }

    nav ul .socmed {
        display: flex;
        justify-content: center;
    }

    nav ul .copyright-container {
        display: flex;
    }
    
}

@media (hover: hover) {
    nav ul li a:not(.active):hover {
        background-color: var(--colorContainer);
    }
}