@media (max-width: 899px) {
    .footer-top,
    .footer-content {
        background-size: 88%;
    }
    .footer-top {
        width: 500px;
        border-radius: 24px;
        min-height: 160px;
    }
    .footer-top h2 {
        font-size: 22px;
    }
    .footer-btn {
        border-radius: 14px;
        margin-top: 25px;
    }
    .footer-btn img {
        width: 26px;
        height: 26px;
    }
    .footer-btn h4 {
        font-size: 14px;
    }
    .footer-content {
        width: 100%;
        height: 260px;
        margin-top: -80px;
    }
    .footer-bottom {
        padding-bottom: 25px;
    }
    .footer-bottom-top img {
        height: 24px;
        border-radius: 14px;
        padding: 5px 8px;
    }
    .footer-bottom-top div h4 {
        font-size: 14px;
        margin: 0 0 0 10px;
        padding: 4px 8px;
        border-radius: 12px;
    }
    .footer-bottom hr {
        margin: 20px 0 10px 0;
    }
    .footer-copyright-img {
        width: 16px;
        height: 16px;
    }
    .footer-copyright-text {
        margin-left: 6px;
        font-size: 14px !important;
    }
    .footer-socmed {
        width: 23px;
        height: 23px;
    }
}

@media (max-width: 599px) {
    .footer-top,
    .footer-content {
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .footer-top {
        width: 92%;
        border-radius: 20px;
        min-height: 130px;
        justify-content: center;
    }
    .footer-top h2 {
        font-size: 16px;
    }
    .footer-btn {
        border-radius: 10px;
        padding: 5px 15px;
        margin-top: 20px;
    }
    .footer-btn img {
        width: 20px;
        height: 20px;
    }
    .footer-btn h4 {
        font-size: 12px;
    }
    .footer-content {
        height: 210px;
        margin-top: -65px;
    }
    .footer-bottom {
        padding-bottom: 20px;
    }
    .footer-bottom-top img {
        height: 18px;
        border-radius: 10px;
        padding: 3px 4px;
    }
    .footer-bottom-top div {
        flex-direction: column;
        justify-content: flex-start;
    }
    .footer-bottom-top div h4 {
        font-size: 13px;
        margin: 0;
        padding: 4px 8px;
        border-radius: 10px;
    }
    .footer-bottom hr {
        margin: 5px 0 10px 0;
    }
    .footer-copyright-img {
        width: 11px;
        height: 11px;
    }
    .footer-copyright-text {
        margin-left: 5px;
        font-size: 12px !important;
    }
    .footer-socmed {
        width: 18px;
        height: 18px;
        margin-left: 18px;
    }
}