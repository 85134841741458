.product-cctv-root {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--colorPrimary);
    background-repeat: repeat-x;
    background-size: 65%;
    background-position: bottom;
    padding: 40px 0;
}

.product-cctv-content {
    width: var(--pageWidth);
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.product-cctv-content h1 {
    color: white;
    text-align: center;
    font-size: 35px;
    margin: 0;
}

.product-cctv-content-item1,
.product-cctv-content-item2 {
    display: flex;
}

.product-cctv-text-container1,
.product-cctv-text-container2 {
    color: white;
}

.product-cctv-text-container1 {
    margin-left: 20px;
}

.product-cctv-text-container2 {
    margin-right: 20px;
    text-align: right;
}

.product-cctv-text-container1 h3,
.product-cctv-text-container2 h3 {
    font-family: Nunito-b;
    font-weight: normal;
    font-size: 22px;
    margin: 0 0 15px 0;
}

.product-cctv-text-container1 p,
.product-cctv-text-container2 p {
    font-family: Nunito;
    font-weight: normal;
    font-size: 18px;
}

.product-cctv-image {
    min-width: 280px;
    aspect-ratio: 2 / 1.1;
    border-radius: 16px;
    overflow: hidden;
}

.product-cctv-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 200ms;
}

@media (hover: hover) {
    .product-cctv-image img:hover {
        transform: scale(1.2);
    }
}