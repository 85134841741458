@media (max-width: 899px) {
    .product-cctv-root {
        background-size: 85%;
        padding: 20px 0;
    }
    .product-cctv-content {
        gap: 35px;
    }
    .product-cctv-content h1 {
        font-size: 28px;
    }
    .product-cctv-text-container1 {
        margin-left: 14px;
    }
    .product-cctv-text-container2 {
        margin-right: 14px;
    }
    
    .product-cctv-text-container1 h3,
    .product-cctv-text-container2 h3 {
        font-size: 18px;
        margin: 0;
    }
    
    .product-cctv-text-container1 p,
    .product-cctv-text-container2 p {
        font-size: 14px;
        margin: 5px 0 0 0;
    }
    
    .product-cctv-image {
        min-width: 200px;
        aspect-ratio: 2 / 1.1;
        border-radius: 12px;
    }
}

@media (max-width: 599px) {
    .product-cctv-root {
        width: 100%;
        background-size: 200%;
        background-position: bottom;
        padding: 30px 0;
    }
    .product-cctv-content {
        gap: 30px;
    }
    .product-cctv-content h1 {
        font-size: 20px;
    }
    .product-cctv-content-item1,
    .product-cctv-content-item2 {
        flex-direction: column;
        justify-content: center;
    }
    .product-cctv-text-container1,
    .product-cctv-text-container2 {
        margin: 0;
        text-align: start;
    }
    .product-cctv-text-container1 h3,
    .product-cctv-text-container2 h3 {
        font-size: 16px;
        margin: 15px 0 5px 0;
    }
    .product-cctv-text-container1 p,
    .product-cctv-text-container2 p {
        font-size: 15px;
        margin: 0;
    }
    .product-cctv-image {
        min-width: 100%;
        border-radius: 16px;
    }
    #product-cctv-img2 {
        order: -1;
    }
}