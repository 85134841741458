.faq-root {
    width: 100%;
    display: flex;
    justify-content: center;
    background-repeat: repeat-x;
    background-size: 60%;
    background-position: bottom;
}

.faq-content {
    width: var(--pageWidth);
    padding-bottom: 20px;
    margin-bottom: var(--contentMarginBottom);
    padding-bottom: var(--contentPaddingBottom);
}

.faq-content h1 {
    width: 200px;
}

.faq-content h2 {
    font-size: 18px;
    margin-top: 40px;
}

.faq-content input {
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    background-color: var(--colorContainer);
    background-size: 25px 25px;
    background-position: 8px 8px;
    background-repeat: no-repeat;
    padding: 12px 5px 12px 40px;
    box-shadow: inset rgb(240, 240, 240) 1px 2px 4px 0px , inset rgb(252, 252, 252) -3.2px -2.2px 2px 0px;
    outline: none;
}

.faq-content input::placeholder {
    color: var(--colorPrimary);
}

.faq-content input:focus {
    box-shadow: inset rgba(53, 183, 57, 0.2) 1px 2px 4px 0px , inset rgb(252, 252, 252) -3.2px -2.2px 2px 0px;
    transition: box-shadow 200ms ease-in;
}

@media (max-width: 599px) {
    .faq-content {
        background-size: 250%;
    }
    .faq-content h1 {
        width: 160px;
    }
    .faq-content h2 {
        font-size: 16px;
        margin-top: 30px;
    }
    .faq-content input {
        font-size: 14px;
        background-size: 22px 22px;
        background-position: 8px 8px;
    }
}