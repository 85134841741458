.app {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

.app-video-player-container,
.app-location-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;
}

.app-video-player {
  width: var(--pageWidth);
  aspect-ratio: 16 / 9;
  border: 0;
  border-radius: 20px;
}

.app-location-dialog {
  background-color: rgba(0, 0, 0, 0.6);
}

/* Barlow */
@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  src: local('BarlowCondensed'), url(./../fonts/BarlowCondensed/BarlowCondensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow-i';
  font-weight: 400;
  src: local('BarlowCondensed'), url(./../fonts/BarlowCondensed/BarlowCondensed-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow-sb';
  font-weight: 600;
  src: local('BarlowCondensed'), url(./../fonts/BarlowCondensed/BarlowCondensed-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow-sbi';
  font-weight: 600;
  src: local('BarlowCondensed'), url(./../fonts/BarlowCondensed/BarlowCondensed-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow-b';
  font-weight: 700;
  src: local('BarlowCondensed'), url(./../fonts/BarlowCondensed/BarlowCondensed-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow-bi';
  font-weight: 700;
  src: local('BarlowCondensed'), url(./../fonts/BarlowCondensed/BarlowCondensed-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow-bl';
  font-weight: 900;
  src: local('BarlowCondensed'), url(./../fonts/BarlowCondensed/BarlowCondensed-Black.ttf) format('truetype');
}

/* Nunito Sans */
@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  src: local('NunitoSans'), url(./../fonts/NunitoSans/NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-i';
  font-weight: 400;
  src: local('NunitoSans'), url(./../fonts/NunitoSans/NunitoSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-sb';
  font-weight: 600;
  src: local('NunitoSans'), url(./../fonts/NunitoSans/NunitoSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-sbi';
  font-weight: 600;
  src: local('NunitoSans'), url(./../fonts/NunitoSans/NunitoSans-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-b';
  font-weight: 700;
  src: local('NunitoSans'), url(./../fonts/NunitoSans/NunitoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito-bi';
  font-weight: 700;
  src: local('NunitoSans'), url(./../fonts/NunitoSans/NunitoSans-BoldItalic.ttf) format('truetype');
}

/* Colors */
:root {
  --colorPrimary: rgb(19, 101, 51);
  --colorPrimaryLight: rgb(30, 131, 69);
  --colorSecondary: rgb(109, 189, 69);
  --colorSecondaryHover: rgb(93, 162, 58);
  --colorThird: rgb(255, 191, 56);
  --colorThirdHover: rgb(224, 169, 48);
  --colorContainer: rgb(247, 247, 247);
  --colorLine: rgb(230, 230, 230);
  --colorShimmerBase: rgb(230, 230, 230);
  --colorShimmer: rgb(245, 245, 245);

  --pageWidth: 900px;
  --contentPaddingBottom: 200px;
  --contentMarginBottom: -100px;
}

@media (max-width: 899px) {
  :root {
    --pageWidth: 600px;
    --contentPaddingBottom: 160px;
    --contentMarginBottom: -80px;
  }
}

@media (max-width: 599px) {
  :root {
    --pageWidth: calc(100vw - 30px);
    --contentPaddingBottom: 130px;
    --contentMarginBottom: -65px;
  }
}