.location-root {
    width: var(--pageWidth);
    background-color: white;
    border-radius: 15px;
    overflow: hidden;
    position: fixed;
    top: 15px;
    bottom: 15px;
}

.location-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.location-content hr {
    width: 100%;
    height: 1px;
    border: 0;
    border-width: 0;
    background-color: rgb(235, 235, 235);
    margin: 0;
}

.location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 15px 0 15px;
    height: var(--locationHeaderHeight);
}

.location-header h1 {
    font-size: 24px;
    margin: 0;
}

.location-header img {
    width: 18px;
    height: 18px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 15px;
    padding: 4px;
    cursor: pointer;
}

.location-header img:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.location-list {
    width: 100%;
    height: calc(100% - var(--locationHeaderHeight));
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: center;
    margin: 0;
}

.location-searchView {
    width: calc(100% - 30px);
    padding: 10px 10px 10px 35px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 6px 7px;
    font-family: Nunito;
    font-weight: normal;
    font-size: 15px;
    color: black;
    border-radius: 8px;
    border: 0;
    box-shadow: inset rgb(240, 240, 240) 1px 2px 4px 0px , inset rgb(252, 252, 252) -3.2px -2.2px 2px 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: none;
    margin: 15px 0 0 0;
}

.location-searchView::placeholder {
    color: rgb(180, 180, 180);
}

.location-searchView:focus {
    box-shadow: inset rgba(53, 183, 57, 0.2) 1px 2px 4px 0px , inset rgb(252, 252, 252) -3.2px -2.2px 2px 0px;
    transition: box-shadow 200ms ease-in;
}

:root {
    --locationHeaderHeight: 70px;
}

@media (hover: hover) {
    .location-header img:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}