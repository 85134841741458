.contact-root {
    width: 100%;
    display: flex;
    justify-content: center;
    background-repeat: repeat-x;
    background-size: 60%;
    background-position: bottom;
}

.contact-content {
    width: var(--pageWidth);
    margin-bottom: var(--contentMarginBottom);
    padding-bottom: var(--contentPaddingBottom);
}

.contact-content-container {
    display: flex;
    justify-content: space-between;
    background-color: var(--colorPrimary);
    border-radius: 25px;
    padding: 30px;
    margin-top: 50px;
}

.contact-content-info {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.contact-content-info h2 {
    color: white;
    font-size: 32px;
    margin: 0 0 20px 0;
}

.contact-info-item {
    display: flex;
    align-items: top;
    margin-top: 10px;
}

.contact-info-item img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    margin-top: 2px;
}

.contact-info-item h3 {
    color: white;
    font-size: 20px;
    margin: 0;
}

.contact-content-map {
    width: 50%;
    height: auto;
    background-color: white;
    border-radius: 15px;
    text-align: center;
    overflow: hidden;
}